import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Location } from '@reach/router'


const PaybrightLogo = ({ width, height }) => null

PaybrightLogo.defaultProps = {
  width: 201.72,
  height: 28,
}

PaybrightLogo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
}

export default PaybrightLogo
