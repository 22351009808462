import React, { useEffect, useRef, useState } from 'react'
import { ActionsContextProvider } from '../context'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import theme from '@pbds/theme'
import CSSReset from '@pbds/css-reset'
import Footer from '@pbds/footer'
import Seo from './Seo'
import Header from './Header'

import './globalStyles.css'
import AnnouncementsBanner from '../shared/AnnouncementsBanner'
import CookieConsentBanner from '../shared/CookieConsentBanner'

const Layout = ({
  children,
  seo,
  announcementsBanner,
  header,
  footer,
  location,
}) => {
  const [isSticky, setSticky] = useState(false)
  const { pathname } = location
  const lang = pathname.slice(1, 3)
  const isFrench = lang === 'fr'

  const stickyRef = useRef(null)
  const handleScroll = () => {
    if (stickyRef.current) {
      window.pageYOffset > stickyRef.current.getBoundingClientRect().top
        ? setSticky(true)
        : setSticky(false)
    }
  }

  typeof window === 'object' && window.addEventListener('scroll', handleScroll)

  useEffect(() => {
    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <ActionsContextProvider>
        <CSSReset />
        <Seo {...seo} />
        {announcementsBanner && (
          <AnnouncementsBanner {...announcementsBanner} />
        )}
        <CookieConsentBanner />
        <Header {...header} isSticky={isSticky} location={location} />
        <main ref={stickyRef}>{children}</main>
        <Footer {...footer} location={location} Link={Link} />
        <h2>{isFrench ?
          "Paybright par Affirm n'a aucune affiliation avec les services trouvés ou proposés sur" :
          "Paybright by Affirm has no affiliation with any services found or offered at"} <a href="http://gopaybright.com/">gopaybright.com</a></h2>
      </ActionsContextProvider>
    </ThemeProvider>
  )
}

Layout.defaultProps = {
  seo: Seo.defaultProps,
  announcementsBanner: AnnouncementsBanner.defaultProps,
  header: Header.defaultProps,
  footer: Footer.defaultProps,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  seo: Seo.propTypes,
  announcementsBanner: AnnouncementsBanner.propTypes,
  header: Header.propTypes,
  footer: Footer.propTypes,
}

export default Layout
